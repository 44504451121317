.navbarColor {
    background-color: darkblue;
}

.margemNavlink {
    margin-left: 15px
}

.tiraMarcador {
    /* Tira marcador da lista do icone sair */
    list-style-type: none;
}

.paddingBotao {
    padding-left: 0px;
}
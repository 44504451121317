.fundoMarcaPonto {
    min-height: 70vh;
    /* background-color: black; */
    /* background-color: #F5F5F5; */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.tamanhoApp {
    min-height: 100vh;
}

.estiloendereco {
    font-size: 0.9rem;
}

.marginTop {
    margin-top: 5%;
}

.labelMarcaPonto {
    color: gray;
    font-weight: bold;
}

.mensagemUsuario {
    color: #fff;
    font-weight: bold;
}

.icon-atualizalocalizacao {
    padding: 0;
}

.ultimaMarcacao {
    color: #fff;
    font-size: 0.8rem;
}

@media (max-width: 767px) {
    .tamanho-componenteFoto {
        max-width: 50%;
    }
    .tamanhoFoto {
        max-width: 100%;
    }
    .mensagemTopo {
        text-align: left;
    }
}

@media (min-width: 768px) {
    .tamanhoFoto {
        max-width: 60%;
    }
    .mensagemTopo {
        text-align: left;
    }
}

.btn-upload {
    color: gray;
    padding: 8px 20px;
    border-radius: 8px;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    margin-bottom: 2%;
    text-align: center;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.form-resize {
    resize: none;
}

.mapa {
    visibility: hidden;
}

.row {
    margin-right: 0px;
    margin-left: 0px;
}

.col {
    margin-top: 0px;
    margin-bottom: 0px;
}

/*.btn-sair{
    background-color: #eb5353;
    color: #fff;
}

.btn-sair:hover {
    color: #fff;
}*/

.margin-bottom-rotate {
    margin-bottom: 0%;
}

.btn-giro {
    border: none;
}

/* .btn-marcaponto{ */

/* background-color: #007bff; */

/* color: #fff; */

/* } */

.modal-sair {
    background-color: rgb(255, 255, 255);
    position: absolute;
    padding: 20px;
    top: 30%;
    left: 10%;
    right: 10%;
}

@media (max-width: 768px) {
    .modal-sair {
        left: 25%;
    }
}

@media (min-width: 768px) {
    .modal-sair {
        left: 30%;
    }
}

@media (min-width: 1024px) {
    .modal-sair {
        left: 30%;
    }
}

@media (min-width: 1044px) {
    .modal-sair {
        left: 30%;
    }
}

.opacidade {
    opacity: 0.5;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1008;
    /* numero maior que o da sideBar para sobrepor ela */
}

.botaoModal {
    padding: 0.25rem 0.25rem;
    /* display: inline; */
}

.margemTop {
    margin-top: 5%;
}

.margemBottom {
    margin-bottom: 5%;
}

.corBotaoMarcaPonto, .corBotaoMarcaPonto:hover {
    background-color: darkblue;
    color: white
}

/* .teste {
    overflow-y: scroll;
    max-height: 400px;
    margin-bottom: 5%; 
} */

.overflow {
    overflow-y: scroll;
    height: 20em;
}

thead {
    background-color: #dee2e6;
}

.modal-sucesso{
    background-color: rgb(255, 255, 255);
    position: absolute;
    padding: 20px;
    top: 30%;
    left: 10%;
    right: 10%;
}

@media (max-width: 768px) {
    .modal-sucesso {
        left: 10%;
    }
}

@media (min-width: 768px) {
    .modal-sucesso {
        left: 20%;
    }
}

@media (min-width: 1024px) {
    .modal-sucesso {
        left: 27%;
    }
}

@media (min-width: 1044px) {
    .modal-sucesso {
        left: 35%;
    }
}
.fundoLogin {
    min-height: 85vh;
    background-color: #007bff;
    background-image: url('../img/fundoIndex.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.labelLogin {
    font-weight: bold;
}

.btn.focus, .btn:focus {
    outline: 0;
    box-shadow: none!important;
}

.row {
    margin-right: 0px;
    margin-left: 0px;
}

.col {
    margin-top: 0px;
    margin-bottom: 0px;
}

.btn-entrar {
    background-color: #00008b;
    color: #fff;
}

.btn-entrar:hover {
    color: #fff;
}

.cardLogin {
    color: #fff;
}

.tamanhoLogo {
    max-width: 50%;
}

.margemBottom {
    margin-bottom: 5%;
}

.alinhamentoCheck {
    padding-top: 5%;
    padding-left: 10px;
    margin-bottom: 0px;
}

.labelTermosUso {
    text-align: center;
    font-style: italic;
    font-size: 0.7rem;
}

.linkTermosUso {
    text-align: center;
    font-weight: bold;
    font-size: 0.7rem;
}

.termoUsoDiv {
    margin-top: 5%;
    margin-bottom: 0;
}
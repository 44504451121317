.footercontent{
    /* background-color: blue; */
    background-color: darkblue;
}

/* .iconPadding{
    margin-top: 2%;
} */

@media (max-width: 450px) and (max-height: 672px){
    .footer-position{
        position: absolute;
    }
}

@media (max-width: 768px) and (max-height: 672px){
    .footer-position{
        position: relative;
    }
}

@media (min-width: 768px) and (max-height: 672px) {
    .footer-position {
        position: relative;
    }
}

@media (min-height: 672px) and (min-width: 768px){
    .footer-position {
        position: absolute;
    }
}

.footersite{
    background-color: darkblue;
    color: white;
    width: 100%;
    bottom: 0px;
}

.color-footer{
    color: white;
}

.colorcopyright{
    color:lightgray;
}

.tamanhofooter{
    min-height: 16.3vh;
}

.paddingBottom{
    padding-bottom: 0.4%;
}

.tamanhoFonte{
    font-size: medium;
}